import React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
function ViewAskSAM({ entries }) {
  const [questionEntries, setQuestionEntries] = useState(null);
  const [currentSelection, setCurrentSelection] = useState(0);
  const [askSamMessage, setAskSamMessage] = useState(null);
  const clearMessage = useRef(null);
  //effect to clear messages after 10 seconds
  useEffect(() => {
    if (askSamMessage !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setAskSamMessage(null);
      }, 10000);
    }
  }, [askSamMessage]);

  //Clearing messages on selection change
  useEffect(() => {
    if (currentSelection !== 0) {
      clearTimeout(clearMessage.current);
      setAskSamMessage(null);
    }
  }, [currentSelection]);
  //Calls makeQuestionMap() each time entries is altered
  useEffect(() => {
    makeQuestionMap();
  }, [entries]);

  //Helepr function to generate the question make from the entries passed in
  const makeQuestionMap = () => {
    let questionMap = new Map();
    for (const askSam of entries) {
      questionMap.set(askSam.id, {
        question: askSam.question,
        whence: askSam.submissionTime,
        submittedBy: askSam.fullName,
        studentEmail: askSam.email,
      });
    }
    setQuestionEntries(questionMap);
  };

  //Helper function to get select + default option for ask sam selection
  const getQuestionList = () => {
    let elementArray = [];
    elementArray.push(
      <select
        defaultValue={currentSelection}
        onChange={checkSamSaysSelection}
        key={`askSamSelect-${entries.length}`}
        id="askSamSelect"
      >
        <option
          value={0}
          onClick={checkSelection}
          id="askSamDefault"
          className="askSamOption"
        >
          Please select a question
        </option>
        {getStudentQuestions()}
      </select>,
    );
    return elementArray;
  };

  const checkSamSaysSelection = (event) => {
    const options = document.getElementsByClassName("askSamOption");
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected && options[i].value !== 0) {
        setCurrentSelection(parseInt(options[i].value));
      }
    }
  };
  //Helper function to get options for all ask sams
  const getStudentQuestions = () => {
    let questionArray = [];
    for (let [key, value] of questionEntries) {
      questionArray.push(
        <option
          key={`questionArrayEntry-${key}`}
          className="askSamOption"
          value={key}
          onClick={checkSelection}
        >
          Question from {value.submittedBy} on {value.whence}
        </option>,
      );
    }
    return questionArray;
  };

  //Onclick handler to update the selected asksam question state
  const checkSelection = (event) => {
    setCurrentSelection(parseInt(event.target.value));
  };

  //Helper function to delete the selected question
  const deleteQuestion = () => {
    setAskSamMessage("Processing...");
    axios
      .delete(
        `https://api.sam.cs.mtu.edu/api/sam/asksam/delete/${currentSelection}`,
        { withCredentials: true },
      )
      .then((response) => {
        let oldMap = questionEntries;
        oldMap.delete(currentSelection);
        setAskSamMessage("Question Deleted!");
        setCurrentSelection(0);
      })
      .catch((error) => {
        setAskSamMessage("Delete Failed!");
        console.log(error);
      });
  };

  //Helper function to get the question info for a selected ask sam question
  const getQuestionInfo = () => {
    return (
      <>
        <h3 className="questionStudentInfo">
          Submitted By {questionEntries.get(currentSelection).submittedBy} on{" "}
          {questionEntries.get(currentSelection).whence}
        </h3>
        <h4 className="questionStudentEmail">
          Students email is {questionEntries.get(currentSelection).studentEmail}
        </h4>
        <div className="askSamFieldsWrapper">
          <textarea
            className="questionInfoPara"
            value={questionEntries.get(currentSelection).question}
            readOnly={true}
          ></textarea>
          <button onClick={deleteQuestion} id="deleteAskSAM">
            Delete Question
          </button>
        </div>
      </>
    );
  };
  return (
    <div className="askSamViewWrapper">
      <h2 className="samDashSubheader">View Ask SAM Questions</h2>
      {questionEntries !== null ? getQuestionList() : null}
      {currentSelection !== 0 ? getQuestionInfo() : null}
      {askSamMessage !== null ? (
        <p className="askSamMessage">{askSamMessage}</p>
      ) : null}
    </div>
  );
}

export default ViewAskSAM;
