import React from "react";
import { useState } from "react";
import "../../Styles/Course.css";
import axios from "axios";

function DownloadMaterial({ courseID, entries }) {
  const [selectedMaterial, setSelectedMaterial] = useState(null);

  //Helper function to create all the option entries for the materials list
  const getSelections = () => {
    let returnArr = [];
    for (const [key, value] of entries) {
      returnArr.push(
        <option
          key={`materialEntry-${courseID}-${key}`}
          className="samMaterialOptions"
          onClick={checkSelection}
          id={`materialEntry-${courseID}-${key}`}
          value={key}
        >
          {value.name}
        </option>,
      );
    }
    return returnArr;
  };

  const checkSelect = () => {
    const options = document.getElementsByClassName("samMaterialOptions");
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        if (parseInt(options[i].value) === 0) {
          setSelectedMaterial(null);
          return;
        } else {
          setSelectedMaterial(parseInt(options[i].value));
          return;
        }
      }
    }
  };
  //Helper function to get all of the material options for a given course
  const getEntries = () => {
    if (entries.size > 0) {
      return (
        <select
          onChange={checkSelect}
          key={`samMaterialOptions-${courseID}`}
          className="samMaterialOptions"
        >
          <option
            key="defaultMaterialSelection"
            className="samMaterialOptions"
            value={0}
            onClick={checkSelection}
          >
            Please make a selection
          </option>
          {getSelections()}
        </select>
      );
    } else {
      return (
        <h3 className="samMaterialSubheader">
          There is no material uploaded for this course yet
        </h3>
      );
    }
  };

  //Onclick handler to update the selectedMaterial state
  const checkSelection = (event) => {
    if (parseInt(event.target.value) === 0) {
      setSelectedMaterial(null);
    } else {
      setSelectedMaterial(parseInt(event.target.value));
    }
  };

  //Helper function to display information regarding the selected material
  const getMaterialInfo = () => {
    if (selectedMaterial === null) return null;
    let materialID = parseInt(
      document.getElementById(`materialEntry-${courseID}-${selectedMaterial}`)
        .value,
    );
    let returnVals = [];
    returnVals.push(
      <p
        className="samMaterialInfo"
        key={`samMaterialInfo-${courseID}-${selectedMaterial}-time`}
      >
        Uploaded on {entries.get(materialID).postTime}
      </p>,
    );
    if (entries.get(materialID).comments !== null) {
      returnVals.push(
        <p
          className="samMaterialInfo"
          key={`samMaterialInfo-${courseID}-${selectedMaterial}-comment`}
        >
          Comments: {entries.get(materialID).comments}
        </p>,
      );
    } else {
      returnVals.push(
        <p
          className="samMaterialInfo"
          key={`samMaterialInfo-${courseID}-${selectedMaterial}-nocomment`}
        >
          No comments provided
        </p>,
      );
    }
    returnVals.push(
      <button
        className="samMaterialDownloadButton"
        onClick={downloadMats}
        key={`samMaterialDownloadButton-${courseID}-${selectedMaterial}`}
      >
        Download
      </button>,
    );
    return returnVals;
  };

  //Onclick handler to download the specified material
  const downloadMats = () => {
    const clientFilename = entries.get(selectedMaterial).name;
    axios
      .get(
        `https://api.sam.cs.mtu.edu/${entries.get(selectedMaterial).fileLocation}`,
        {
          responseType: "blob",
        },
      )
      .then((response) => {
        const href = URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", clientFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };
  return (
    <div className="fileSharingWrapper">
      <h2 className="samDashSubheader">Download Course Materials</h2>
      {entries !== null ? (
        getEntries()
      ) : (
        <h3 className="samMaterialSubheader">Loading...</h3>
      )}
      {entries !== null && selectedMaterial !== null ? getMaterialInfo() : null}
    </div>
  );
}

export default DownloadMaterial;
