import React from 'react';
import '../../Styles/Course.css';
import { useState, useEffect, useRef } from 'react';
function UploadMaterial({ upload, message, setMessage }) {
    const [showFields, setShowFields] = useState(false);
    const clearMessage = useRef(null);
    useEffect(()=>{
        if(message!==null){
            clearTimeout(clearMessage.current);
            clearMessage.current = setTimeout(()=>{
                setMessage(null);
            },10000)
        }
    },[message])
    //Helper function to clear the upload fields
    const clearFields = () => {
        document.getElementById("samMaterialUpload").value = null;
        setShowFields(false);
    }

    //Helper function that forms the uploaded file to be sent to the api
    const uploadFile = () => {
        let form = new FormData();
        const matFile = document.getElementById('samMaterialUpload');
        form.append('file', matFile.files[0]);
        form.append('comments', document.getElementById("uploadMaterialsComments").value)
        upload(form);
        clearFields();

    }
    //Onchange handler to display more fields once a user uploads a file
    const checkField = () => {
        setMessage(null);
        setShowFields(true);
    }

    //Helper function to get the file upload parameters once a user uploads a file
    const getFields = () => {
        return (
            <>
                <div className="fileUploadCommentWrapper">
                    <label className="fileSubheader">Comments:</label>
                    <input id="uploadMaterialsComments" type="text" placeholder="Optional" />
                </div>
                <div className="uploadMaterialsButtonWrapper">
                    <button className="uploadMaterialsButton" onClick={uploadFile} id="uploadMaterialsSubmit">Upload</button>
                    <button className="uploadMaterialsButton" id="uploadMaterialsCancel">Cancel</button>
                </div>
            </>
        )
    }
    return (
        <div className="fileSharingWrapper">
            <h2 className="samDashSubheader">Upload Course Materials</h2>
            <div className="uploadMaterialsWrapper">
                <input type="file" id="samMaterialUpload" onChange={checkField} required />
                {
                    showFields ? getFields() : null
                }
            </div>
            {
                message !== null ? <p className="uploadMaterialMessage">{message}</p> : null
            }
        </div>
    );
}

export default UploadMaterial;
