import React from "react";
import "../../Styles/sam.css";
import axios from "axios";
import { useState, useEffect } from "react";
import FormData from "form-data";
function UpdateProfile() {
  axios.defaults.withCredentials = true;
  const [messages, setMessages] = useState([]);
  const [profile, setProfile] = useState(null);
  //Helper function to post the updated profile description
  const updateDescription = () => {
    setMessages("Processing...");
    const newDesc = document.getElementById("samProfileUpdate").value;
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/updateProfile", {
        withCredentials: true,
        description: newDesc,
      })
      .then((response) => {
        setMessages("Profile updated successfully");
      })
      .catch((error) => {
        setMessages("Request failed, please retry later");
      });
  };
  const handleImageError = (event) => {
    event.target.src = "https://api.sam.cs.mtu.edu/defaults/no_photo.jpg";
  };
  //Helper function to post a sams profile picture
  const updatePhoto = () => {
    setMessages("Processing...");
    let form = new FormData();
    const photoFile = document.getElementById("samPhotoUpload");
    form.append("file", photoFile.files[0]);
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/uploadPhoto", form, {
        withCredentials: true,
        header: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setMessages("Photo updated successfully");
        fetchProfile();
      })
      .catch((error) => {
        setMessages("Photo update failed");
      });
  };
  const fetchProfile = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/myprofile")
      .then((response) => {
        setProfile(Object.values(response.data));
      })
      .catch((error) => console.log(error));
  };
  //Fetches a sams profile information on load
  useEffect(() => {
    fetchProfile();
  }, []);

  //Clears messages after 10 seconds
  useEffect(() => {
    if (messages != null && messages !== "Processing...") {
      setTimeout(() => {
        setMessages(null);
      }, 10000);
    }
  }, [messages]);
  return (
    <div className="updateProfileWrapper">
      {/* <h2 className="samSubheader">Update Profile</h2> */}
      <div className="samProfileUpdateOptions">
        <div className="samProfileUpdateDescDiv">
          <h3 className="samDoubleSubHeader">Update Profile Description:</h3>
          {profile === null ? (
            <textarea
              key={`profileDescription-Loading`}
              id="samProfileUpdateLoading"
              defaultValue={"Loading..."}
            ></textarea>
          ) : (
            <textarea
              key={`profileDescription-loaded`}
              id="samProfileUpdate"
              defaultValue={profile[0]}
            ></textarea>
          )}
          <button className="samDashboardButton" onClick={updateDescription}>
            Update
          </button>
        </div>
        <div className="samProfileUpdatePhotoDiv">
          {profile !== null ? (
            <div className="samDashPhotoWrapper">
              <img
                onError={handleImageError}
                className="samDashProfilePhoto"
                src={`https://api.sam.cs.mtu.edu/${profile[1]}`}
                alt="No Photo"
              ></img>
              <button
                id="uploadImageButton"
                onClick={() =>
                  document.getElementById("samPhotoUpload").click()
                }
              >
                +
              </button>
            </div>
          ) : null}
          <input
            type="file"
            title={"This doesnt work"}
            id="samPhotoUpload"
            onChange={() =>
              document
                .getElementById("samDashboardUploadButton")
                .classList.toggle("notVisible")
            }
            accept="image/png, image/jpeg, image/jpg"
            required
          />
          <button
            id="samDashboardUploadButton"
            className="notVisible"
            onClick={() => {
              updatePhoto();
              document
                .getElementById("samDashboardUploadButton")
                .classList.toggle("notVisible");
            }}
          >
            Submit
          </button>
        </div>
      </div>
      {messages === undefined ? undefined : (
        <p className="samDashMessage">{messages}</p>
      )}
    </div>
  );
}

export default UpdateProfile;
