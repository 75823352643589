import React from "react";
import { useState, useEffect, useRef } from "react";
import SearchBar from "../SearchBar";
import axios from "axios";
import "../../Styles/sam.css";
import MultiSelect from "../MultiSelect";
import StudentEntry from "./StudentEntry";
function UpdateAttendance({ sessions, selectedCourse, studentList }) {
  const [selectedSessionID, setSelectedSessionID] = useState(0);
  const [attendanceRecords, setAttendanceRecords] = useState(null);
  const [showSearch, setShowSearch] = useState(false);
  const [attendanceMessage, setAttendanceMessage] = useState(null);
  const selectedStudents = useRef([]);
  const selectedSesh = useRef();
  const messageTimeout = useRef(null);

  useEffect(() => {
    if (attendanceMessage !== null && attendanceMessage !== "Processing...") {
      clearTimeout(messageTimeout.current);
      messageTimeout.current = setTimeout(() => {
        setAttendanceMessage(null);
      }, 10000);
    }
  }, [attendanceMessage]);

  //Resets selections and other states whenever the selected course is changed
  useEffect(() => {
    setSelectedSessionID(0);
    setShowSearch(false);
    setAttendanceMessage(null);
  }, [selectedCourse]);

  //Fetches attendance whenever selected session is changed
  useEffect(() => {
    if (selectedSessionID !== 0) {
      fetchAttendance();
    } else {
      if (attendanceRecords === null && showSearch === false) return;
      if (attendanceRecords !== null) setAttendanceRecords(null);
      if (showSearch !== false) setShowSearch(false);
      if (attendanceMessage !== null) setAttendanceMessage(null);
    }
  }, [selectedSessionID]);

  //Helper function to fetch attendance data from the api
  const fetchAttendance = () => {
    axios
      .get(
        `https://api.sam.cs.mtu.edu/api/sam/getAttendance/${selectedSessionID}`,
        {
          withCredentials: true,
        },
      )
      .then((response) => {
        setAttendanceRecords(response.data.returnArr);
      })
      .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (attendanceRecords !== null) updateSelections();
  }, [attendanceRecords]); //Updates selections when attendaceRecords changes

  //Update list of students in attendance + generate the SearchBar/buttons to submit attendance changes
  const updateSelections = () => {
    if (attendanceRecords === null) {
      selectedStudents.current = [];
    } else {
      let newSelections = [];
      attendanceRecords.forEach((entry) => {
        newSelections.push(entry[0]);
      });
      selectedStudents.current = newSelections;
    }
    generateSearch();
  };

  //Generates Search items
  const generateSearch = () => {
    let returnArr = [];
    returnArr.push(
      <h3 key="samDashUpdateSubheader" className="samDashSubheader">
        Update Attendance
      </h3>,
    );
    returnArr.push(
      <MultiSelect
        key={`multiSelectFor${selectedSessionID}`}
        entries={studentList}
        selections={selectedStudents}
      />,
    );
    returnArr.push(
      <div className="attendanceButtonWrapper" key="attendanceButtonWrapperDiv">
        <button
          key="samDashUpdateAttendanceSubmit"
          className="modifyAttendanceButton"
          onClick={updateAttendance}
        >
          Submit
        </button>
        <button
          key="samDashUpdateAttendanceCancel"
          className="modifyAttendanceButton"
          onClick={resetSelections}
        >
          Cancel
        </button>
      </div>,
    );
    setShowSearch(returnArr);
  };

  //Helper function to post the updated attendance
  const updateAttendance = () => {
    let attendanceArr = [];
    studentList.forEach((student) => {
      if (selectedStudents.current.includes(student[1])) {
        attendanceArr.push([student[1], true]);
      } else {
        attendanceArr.push([student[1], false]);
      }
    });
    setAttendanceMessage("Processing...");
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/sam/updateAttendance/${selectedSessionID}`,
        {
          withCredentials: true,
          studentList: attendanceArr,
        },
      )
      .then((response) => {
        fetchAttendance();

        setAttendanceMessage("Attendance Updated!");
      })
      .catch((error) => console.log(error));
  };

  const resetSelections = () => {
    setAttendanceMessage("");
    setSelectedSessionID(0);
  };

  //Generates drop down list of sessions for SAM to select from
  const sessionValues = () => {
    let returnSet = [];
    sessions.forEach((session) => {
      if (session.courseID === selectedCourse) {
        let currentSesh = [];
        currentSesh.push(session.Date);
        currentSesh.push(session.sessionID);
        returnSet.push(currentSesh);
      }
    });
    return (
      <SearchBar
        name={"sessions"}
        entries={returnSet}
        placeholderText={"Please select a session"}
        selections={selectedSesh}
        setState={setSelectedSessionID}
        selectState={selectedSessionID}
        isMulti={false}
      />
    );
  };

  const updateStudentBonusPoints = (studentUsername, pointCount) => {
    axios
      .patch(
        `https://api.sam.cs.mtu.edu/api/sam/bonuspoints/${selectedSessionID}`,
        {
          studentUsername: studentUsername,
          bonusPoints: pointCount,
        },
      )
      .then((response) => {
        fetchAttendance();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  //Helper function to return li entry for each student in attendance
  const getStudents = () => {
    let returnArr = [];
    attendanceRecords.forEach((student) => {
      returnArr.push(
        <StudentEntry
          key={`studentAttendanceEntry-${student[0]}-${selectedSessionID}`}
          studentName={student[1]}
          studentUsername={student[0]}
          bonusPointCount={student[2]}
          updatePoints={updateStudentBonusPoints}
        />,
      );
    });
    return returnArr;
  };

  //Helper function to generate attendance table
  const getAttendanceRecords = () => {
    let returnArr = [];
    returnArr.push(
      <h3
        key={`attendanceCount${selectedSessionID}`}
        className="attendanceSubheader"
      >
        Students Attended: {attendanceRecords.length}
      </h3>,
    );
    if (attendanceRecords !== null && attendanceRecords.length > 0) {
      returnArr.push(
        <div key="attendanceStudentsList" className="attendanceStudentList">
          {getStudents()}
        </div>,
      );
    }
    return returnArr;
  };

  return (
    <div className="samAttendanceWrapper">
      <h2 className="samAttendanceHeader">Attendance</h2>
      {sessions === undefined ? null : sessionValues()}
      {attendanceRecords !== null ? getAttendanceRecords() : null}
      {showSearch !== false ? (
        <div className="modifyAttendanceWrapper">{showSearch}</div>
      ) : null}
      <p id="attendanceParaMessage" className="attendanceMessage">
        {attendanceMessage}
      </p>
    </div>
  );
}

export default UpdateAttendance;
