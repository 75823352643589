import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Context } from "./App";
import axios from "axios";
import Offcanvas from 'react-bootstrap/Offcanvas';
import CourseBanner from '../components/CourseBanner';
import ViewProblems from '../components/CourseComponents/ViewProblems';
import CourseSchedule from "../components/CourseComponents/CourseSchedule";
import SAMSays from '../components/CourseComponents/SAMSays';
import CourseNextSession from '../components/CourseComponents/CourseNextSession';
import NextWeek from '../components/CourseComponents/NextWeek';
import AskSam from '../components/CourseComponents/AskSam';

const Course = () => {
    let { courseID } = useParams();
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useContext(Context);
    const [courseData, setCourseData] = useState(null);
    const [renderSamSays, setRenderSamSays] = useState(false);
    const [renderSchedule, setRenderSchedule] = useState(false);
    const [renderNextSession, setRenderNextSession] = useState(false);
    const [renderNextWeek, setRenderNextWeek] = useState(false);
    const [isDebounced, setIsDebounced] = useState(false);
    const typedSamSays = useRef(null);
    const courses = [2311, 2321, 3331, 3411];
    const quote = useRef(null);
    const quoteSayer = useRef(null);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (courses.includes(parseInt(courseID))) {
            typedSamSays.current = false;
            setLoading(true);
            axios
                .get(
                    `https://api.sam.cs.mtu.edu/api/course/getinfo/${courseID}`
                )
                .then((response) => {
                    let fullCourseData = [];
                    let courseInfo = [];
                    let samSaysData = [];
                    //Creating course info array
                    courseInfo.push(response.data[0][0].courseID);
                    courseInfo.push(response.data[0][0].courseName);
                    courseInfo.push(response.data[0][0].courseDescription);
                    courseInfo.push(response.data[1][0].sectionInfo);
                    //Creating SAM Says 'array' which holds a singular object
                    if (response.data[2][0].message !== undefined) {
                        samSaysData.push({
                            message: response.data[2][0].message,
                            date: response.data[2][0].Date,
                            whom: response.data[2][0].SAM,
                        });
                        setRenderSamSays(true);
                        quote.current = response.data[2][0].message;
                        quoteSayer.current = `- ${response.data[2][0].SAM} on ${response.data[2][0].Date}`;
                    } else {
                        setRenderSamSays(false);
                    }
                    fullCourseData.push(courseInfo);
                    fullCourseData.push(samSaysData);
                    //Inserting course schedule array if there is any sessions scheduled
                    if (response.data[3].length !== 0) {
                        fullCourseData.push(response.data[3]);
                        setRenderSchedule(true);
                    } else {
                        setRenderSchedule(false);
                    }
                    if (response.data[4].length !== 0) {
                        fullCourseData.push(response.data[4]);
                        setRenderNextSession(true);
                    } else {
                        setRenderNextSession(false);
                    }
                    if (response.data[6].length > 0) {
                        fullCourseData.push(response.data[6]);
                        setRenderNextWeek(true);
                    } else {
                        setRenderNextWeek(false);
                    }
                    setCourseData(fullCourseData);
                    //Responsedata at 0 is course info
                    //Responsedata at 1 is section info
                    //Responsedata at 2 is sam says
                    //Responsedata at 3 is weekly schedule
                    //Responsedata at 4 is next session
                    setLoading(false);
                });
        } else {
            setLoading(false);
        }
    }, [courseID]);

    console.log(courseData);

    if (loading) {
        return (
            <div id="loader-overflow">
                <div id="loader3" className="loader-cont">Please enable JS</div>
            </div>
        );
    }

    return (
        <>
            <CourseBanner samSays={courseData[1]} />
            <div className="main-content">
                <section className="pt-60 pb-40">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 align-self-lg-center">
                                <div className="about-caption courseBanner text-center">
                                    <h6 className="title">
                                        {courseData
                                            ? `CS${courseData[0][0]}: ${courseData[0][1]}`
                                            : ""}
                                    </h6>
                                    <p>{courseData ? courseData[0][2] : ""}</p>

                                    <button
                                        className="myBtn"
                                        onClick={handleShow}
                                    >
                                        View Practice Problems
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            <Offcanvas
                show={show}
                placement="end"
                onHide={handleClose}
                className="offcanvas-dark"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Practice Problems</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ViewProblems
                        key={`practiceProblemsFor-${courseID}`}
                        courseID={courseID}
                    />
                </Offcanvas.Body>
            </Offcanvas>

            {Array.isArray(courseData) && courseData[2] ? (
                <section className="courses-bg-2 pt-80 pb-80" data-overlay="04">
                    <div className="container">
                        <div className="section-title-2 text-center">
                            <h6
                                className="title"
                                style={{ color: "white", fontSize: "30px" }}
                            >
                                SAM Session Schedule
                            </h6>
                            {courseData[3] ?
                                <p style={{ color: "white" }}>
                                    {`Next session is on ${courseData[3][0]?.time} in ${courseData[3][0].sessionLocation} with ${courseData[3][0].SAM}`}
                                </p> : ""}
                        </div>
                        <div className="col-lg-12 col-md-12">
                            <CourseSchedule
                                key={`courseScheduleFor${courseID}`}
                                courseID={courseID}
                                scheduleData={courseData[2]}
                            />
                        </div>
                    </div>
                </section>
            ) : (
                <section className="courses-bg-2 pt-80 pb-80" data-overlay="04">
                    <div className="container">
                        <div className="section-title-2 text-center">
                            <h6
                                className="title"
                                style={{ color: "white", fontSize: "30px" }}
                            >
                                Course Schedule Unavailable!
                            </h6>
                        </div>
                    </div>
                </section>
            )}

            {userInfo?.Signin ? <AskSam key={`courseAskSamFor${courseID}`} courseID={courseID} /> : ""}

        </>
    );
};

export default Course;