import React from "react";
import { useState, useEffect, useRef } from "react";
import NewProblem from "./PracticeQuestionsComponents/NewProblem";
import ViewProblem from "./PracticeQuestionsComponents/ViewProblem";
import axios from "axios";
function PracticeProblems({ selectedCourse, whom }) {
  const [selectedProblem, setSelectedProblem] = useState(-1);
  const [problemList, setProblemList] = useState(null);
  const [addMessage, setAddMessage] = useState(null);
  const clearMessage = useRef(null);
  const categoryRef = useRef(null);
  const cateMap = useRef(null);
  const deletion = useRef(null);
  const checkSelection = (event) => {
    setSelectedProblem(parseInt(event.target.value));
  };
  useEffect(() => {
    if (deletion.current === true) {
      deletion.current = false;
    } else {
      setAddMessage(null);
    }
  }, [selectedProblem]);
  useEffect(() => {
    categoryRef.current = null;
    fetchProblems();
  }, [selectedCourse]);
  useEffect(() => {
    if (addMessage !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setAddMessage(null);
      }, 10000);
    }
  }, [addMessage]);
  const fetchProblems = () => {
    axios
      .get(
        `https://api.sam.cs.mtu.edu/api/course/practiceproblems/${selectedCourse}`,
      )
      .then((response) => {
        let problemMap = new Map();
        let categoryMap = new Map();
        let categorySet = new Set();
        response.data.forEach((entry) => {
          categorySet.add(entry.topic);
          problemMap.set(parseInt(entry.problemID), {
            name: entry.problemName,
            topic: entry.topic,
            description: entry.description,
            hint: entry.hint,
            comments: entry.comments,
          });
          if (categoryMap.has(entry.topic)) {
            let previousValue = categoryMap.get(entry.topic);
            previousValue.push({
              id: entry.problemID,
              name: entry.problemName,
            });
            categoryMap.set(entry.topic, previousValue);
          } else {
            categoryMap.set(entry.topic, [
              {
                id: entry.problemID,
                name: entry.problemName,
              },
            ]);
          }
        });
        categoryRef.current = categorySet;
        cateMap.current = categoryMap;
        setProblemList(problemMap);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAllProblems = () => {
    let returnArray = [];
    categoryRef.current.forEach((category) => {
      returnArray.push(
        <optgroup
          key={`problemOptgroup-${category}-${selectedCourse}`}
          label={category}
          className="problemSelectionOptionGroup"
        >
          {getAllProblemsInCategory(category)}
        </optgroup>,
      );
    });
    return returnArray;
  };
  const getAllProblemsInCategory = (category) => {
    let selectArray = [];
    cateMap.current.get(category).forEach((problem) => {
      selectArray.push(
        <option
          key={`problemIn${category}-${problem.id}`}
          className="practiceProblemSelectOption"
          onClick={checkSelection}
          value={problem.id}
        >
          {problem.name}
        </option>,
      );
    });
    return selectArray;
  };
  const getViewProblem = () => {
    let entry = problemList.get(selectedProblem);
    let info = [
      entry.topic,
      entry.name,
      entry.description,
      entry.hint,
      entry.comments,
    ];
    return (
      <ViewProblem
        key={`viewProblem=${selectedProblem}-${selectedCourse}`}
        updateFunction={updateProblem}
        deleteFunction={deleteProblem}
        problemInfo={info}
        categories={categoryRef.current}
      />
    );
  };
  const addProblem = (problemInfo) => {
    setAddMessage("Processing...");
    axios
      .post(
        `https://api.sam.cs.mtu.edu/api/${whom}/addproblem/${selectedCourse}`,
        {
          name: problemInfo[1],
          topic: problemInfo[4],
          description: problemInfo[0],
          comments: problemInfo[3],
          hint: problemInfo[2],
        },
        { withCredentials: true },
      )
      .then((response) => {
        setAddMessage("Problem Added!");
        fetchProblems();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const updateProblem = (problemInfo) => {
    setAddMessage("Updating...");
    axios
      .patch(
        `https://api.sam.cs.mtu.edu/api/${whom}/updateproblem/${selectedProblem}`,
        {
          name: problemInfo[1],
          topic: problemInfo[4],
          description: problemInfo[0],
          comments: problemInfo[3],
          hint: problemInfo[2],
        },
        { withCredentials: true },
      )
      .then((response) => {
        setAddMessage("Problem Updated!");
        fetchProblems();
      })
      .catch((error) => console.log(error));
    console.log(problemInfo);
  };
  const deleteProblem = (problemID) => {
    setAddMessage("Processing...");
    axios
      .delete(
        `https://api.sam.cs.mtu.edu/api/${whom}/deleteproblem/${selectedProblem}`,
        { withCredentials: true },
      )
      .then((response) => {
        deletion.current = true;
        setSelectedProblem(-1);
        fetchProblems();
        setAddMessage("Problem Deleted!");
      })
      .catch((error) => {
        setAddMessage("Deletion Failed");
      });
  };

  const checkProblemSelection = () => {
    const problems = document.getElementsByClassName(
      "practiceProblemSelectOption",
    );
    for (let i = 0; i < problems.length; i++) {
      if (
        problems[i].selected &&
        problems[i].value !== -1 &&
        problems[i] !== -2
      ) {
        setSelectedProblem(parseInt(problems[i].value));
      }
    }
  };
  return (
    <div className="PracticeProblems">
      <h2 className="practiceProblemHeader">
        Create or View Practice Problems
      </h2>
      <select
        onChange={checkProblemSelection}
        defaultValue={selectedProblem}
        id="practiceProblemSelect"
      >
        {problemList !== null ? (
          <>
            {" "}
            <option
              value={-1}
              onClick={checkSelection}
              className="practiceProblemSelectOption"
            >
              Please make a selection
            </option>
            <option
              value={-2}
              onClick={checkSelection}
              className="practiceProblemSelectOption"
            >
              Create a new problem
            </option>
            {getAllProblems()}
          </>
        ) : (
          <option value={-1} className="practiceProblemSelectionOption">
            Loading...
          </option>
        )}
      </select>
      {selectedProblem !== -1 ? (
        selectedProblem !== -2 ? (
          getViewProblem()
        ) : (
          <NewProblem
            categories={categoryRef.current}
            submitFunction={addProblem}
            message={addMessage}
          />
        )
      ) : null}
      {addMessage !== null ? (
        <p className="problemMessaging">{addMessage}</p>
      ) : null}
    </div>
  );
}

export default PracticeProblems;
