import React from "react";
import { useState } from "react";
function NewProblem({ submitFunction, categories }) {
  const [problemMessage, setProblemMessage] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const getCategories = () => {
    let categoryArray = [];
    categories.forEach((entry) => {
      categoryArray.push(
        <option
          key={`categorySelection-${entry}`}
          className="newProblemCategorySelect"
          onClick={checkSelection}
          value={entry}
        >
          {entry}
        </option>,
      );
    });
    return categoryArray;
  };
  const checkSelection = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      setSelectedCategory(event.target.value);
    } else {
      setSelectedCategory(parseInt(event.target.value));
    }
  };
  const submitProblem = () => {
    const desc = document.getElementById("newProblemDescription").value;
    const name = document.getElementById("newProblemName").value;
    const hint =
      document.getElementById("newProblemHint").value.length === 0
        ? null
        : document.getElementById("newProblemHint").value;
    const comment =
      document.getElementById("newProblemComments").value.length === 0
        ? null
        : document.getElementById("newProblemComments").value;
    if (desc.length === 0) {
      setProblemMessage("Problem Description cannot be empty");
      return;
    }
    if (name.length === 0) {
      setProblemMessage("Problem Name cannot be empty");
      return;
    }
    let category;
    if (selectedCategory === -1) {
      category = document.getElementById("newProblemNewCategoryText").value;
      if (category.length === 0) {
        setProblemMessage("Category cannot be empty");
        return;
      }
      let values = [desc, name, hint, comment, category];
      submitFunction(values);
    } else {
      let values = [desc, name, hint, comment, selectedCategory];
      submitFunction(values);
    }
  };
  const checkCategoryChange = (event) => {
    const options = document.getElementsByClassName("newProblemCategorySelect");
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        setSelectedCategory(parseInt(options[i].value));
        break;
      }
    }
  };
  return (
    <div className="newPracticeProblemWrapper">
      <select onChange={checkCategoryChange} id="newProblemCategorySelector">
        <option
          id="newProblemCategoryDefault"
          value={0}
          className="newProblemCategorySelect"
          onClick={checkSelection}
        >
          Please select a category
        </option>
        <option
          id="newProblemNewCategory"
          value={-1}
          className="newProblemCategorySelect"
          onClick={checkSelection}
        >
          Create new category
        </option>
        {getCategories()}
      </select>
      {selectedCategory === -1 ? (
        <>
          <label className="newProblemLabel">Category:</label>
          <input
            type="text"
            id="newProblemNewCategoryText"
            placeholder="Required"
          ></input>
        </>
      ) : null}
      <label className="newProblemLabel">Problem Name:</label>
      <input type="text" id="newProblemName" placeholder="Required"></input>
      <label className="newProblemLabel">Description:</label>
      <textarea
        cols="30"
        rows="10"
        id="newProblemDescription"
        placeholder="Required"
      ></textarea>
      <label className="newProblemLabel">Hint:</label>
      <textarea
        cols="30"
        rows="10"
        id="newProblemHint"
        placeholder="Optional"
      ></textarea>
      <label className="newProblemLabel">Comments:</label>
      <textarea
        cols="30"
        rows="10"
        id="newProblemComments"
        placeholder="Optional"
      ></textarea>
      <button id="newProblemSubmit" onClick={submitProblem}>
        Create Problem
      </button>
      {problemMessage !== null ? (
        <p className="newProblemMessage">{problemMessage}</p>
      ) : null}
    </div>
  );
}

export default NewProblem;
