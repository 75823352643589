import React from "react";
import { useState, useRef, useEffect } from "react";
function UpdateSamSays({currentSamSays, updateFunction, defaultMessage}){
    const [updateSamSaysMessage, setUpdateSamSaysMessage] = useState(defaultMessage);
    const clearMessage = useRef(null);
    useEffect(()=>{
        if(updateSamSaysMessage!==null){
            clearTimeout(clearMessage.current);
            clearMessage.current = setTimeout(()=>{
                setUpdateSamSaysMessage(null);
            },10000)
        }
    },[updateSamSaysMessage])
    //Helper function that post that checks the fields for a sam says update and calls the update prop function
    const updateSamSays = ()=>{
        const newMessage = document.getElementById("samSaysTextField").value;
        if(newMessage===currentSamSays){
            setUpdateSamSaysMessage("Please modify the SAM Says before submission");
        }else if(newMessage===""){
            setUpdateSamSaysMessage("Message must not be empty!");
        }else{
            let updateMessage = "Processing";
            setUpdateSamSaysMessage(updateMessage);
            const sendUpdate = async ()=>{
                let response = await updateFunction(newMessage);
                updateMessage = response;
            }
            sendUpdate().then(response=>{
                setUpdateSamSaysMessage(updateMessage);
            })
        }
    }
    return(
        <div className="updateSamSaysWrapper">
            <h2 className="samDashSubheader">Update SAM Says</h2>
            {
                currentSamSays===null || currentSamSays===undefined?
                <p key={`samSaysLoading`} className="samDashParagraph">Loading...</p>:
                <><textarea key={`samSaysTextArea-${currentSamSays.length}`}id="samSaysTextField" defaultValue={currentSamSays} className="samSaysInput"></textarea>
                <button id="updateSamSaysButton" onClick={updateSamSays}>Submit</button></>
            }
            {
                updateSamSaysMessage!==null?<p className="samDashPara">{updateSamSaysMessage}</p>:null
            }
        </div>
    )

}
export default UpdateSamSays