import React from "react";
/*
    This component just displays the passed in problem information
*/
function ProblemInfo({data}){

    return(
        <div className="problemInfoWrapper mt-5">
            <p className="problemInfoParagraph" id="problemDescParagraph"><span className="coursePageProblemLabel">{data.name}:</span> {data.description}</p>
            {
                data.hint!==null?<p className="problemInfoParagraph" id="problemHintParagraph"><span className="coursePageProblemLabel">Hint:</span> {data.hint}</p>:null
            }
                        {
                data.comments!==null?<p className="problemInfoParagraph" id="problemCommentParagraph"><span className="coursePageProblemLabel">Comments:</span> {data.comments}</p>:null
            }
        </div>
    )
}

export default ProblemInfo;