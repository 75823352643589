import React from "react";
import { useState, useEffect } from "react";
function ViewProblem({ problemInfo, updateFunction, deleteFunction, categories }) {
    const [selectedCategory, setSelectedCategory] = useState(problemInfo[0]);
    const [isEditing, setIsEditing] = useState(false);
    const [problemMessage, setProblemMessage] = useState(null);
    const checkSelection = (event) => {
        if (isNaN(parseInt(event.target.value))) {
            setSelectedCategory(event.target.value);
        } else {
            setSelectedCategory(parseInt(event.target.value));
        }
    }
    const getCategories = () => {
        let categoryArray = [];
        categories.forEach((entry) => {
            categoryArray.push(<option key={`categorySelection-${entry}`} className="newProblemCategorySelect" onClick={checkSelection} value={entry}>{entry}</option>)
        })
        return categoryArray;
    }
    const updateProblem = () => {
        const desc = document.getElementById("newProblemDescription").value;
        const name = document.getElementById("newProblemName").value;
        const hint = (document.getElementById("newProblemHint").value).length === 0 ? null : document.getElementById("newProblemHint").value;
        const comment = (document.getElementById("newProblemComments").value).length === 0 ? null : document.getElementById("newProblemComments").value;
        if (desc.length === 0) {
            setProblemMessage("Problem Description cannot be empty");
            return;
        }
        if (name.length === 0) {
            setProblemMessage("Problem Name cannot be empty");
            return;
        }
        let category;
        if (selectedCategory === -1) {
            category = document.getElementById("newProblemNewCategoryText").value;
            console.log(category);
            if (category.length === 0) {
                setProblemMessage("Category cannot be empty");
                return;
            }
            let values = [desc, name, hint, comment, category];
            updateFunction(values);
        } else {
            let values = [desc, name, hint, comment, selectedCategory];
            updateFunction(values);
        }
    }
    return (
        <div className="practiceProblemViewWrapper">
            <div className="samDashProblemInfoWrapper">
            <label className="newProblemLabel">Category:</label>
            <select defaultValue={problemInfo[0]} disabled={!isEditing} id="newProblemCategorySelector">
                <option id="newProblemCategoryDefault" value={0} className="newProblemCategorySelect" onClick={checkSelection}>Please select a category</option>
                <option id="newProblemNewCategory" value={-1} className="newProblemCategorySelect" onClick={checkSelection}>Create new category</option>
                {
                    getCategories()
                }
            </select>
            {
                selectedCategory === -1 ?
                    <>
                        <input type="text" id="newProblemNewCategoryText" placeholder="Required"></input>
                    </>
                    : null
            }
            </div>
            <div className="samDashProblemInfoWrapper">
                <label className="newProblemLabel">Problem Name:</label>
                <input disabled={!isEditing} defaultValue={problemInfo[1]} type="text" id="newProblemName" placeholder="Required"></input>
            </div>
            <div className="samDashProblemInfoWrapper">
                <label className="newProblemLabel">Description:</label>
                <textarea disabled={!isEditing} defaultValue={problemInfo[2] === null ? "" : problemInfo[2]} cols="30" rows="10" id="newProblemDescription" placeholder="Required"></textarea>
            </div>
            <div className="samDashProblemInfoWrapper">
                <label className="newProblemLabel">Hint:</label>
                <textarea disabled={!isEditing} readOnly={false} defaultValue={problemInfo[3] === null ? "" : problemInfo[3]} cols="30" rows="10" id="newProblemHint" placeholder="Optional"></textarea>
            </div>
            <div className="samDashProblemInfoWrapper">
                <label className="newProblemLabel">Comments:</label>
                <textarea disabled={!isEditing} cols="30" defaultValue={problemInfo[4] === null ? "" : problemInfo[4]} rows="10" id="newProblemComments" placeholder="Optional"></textarea>
            </div>
            <div className="problemButtonWrapper">
                <button className={`${isEditing?"editingButton":""} editingProblemButton`} onClick={(event) => { if (!isEditing) { setIsEditing(true); } else { updateProblem() } }} id="updateProblemButton">{isEditing ? "Update" : "Edit"}</button>
                <button className={`${isEditing?"editingButton":""} editingProblemButton`} onClick={deleteFunction} id="deleteProblemButton">Delete</button>
                {
                    isEditing ? <button className={`${isEditing?"editingButton":""} editingProblemButton`} onClick={() => { setIsEditing(false) }} id="cancelEditing">Cancel</button> : null
                }
            </div>
            {
                problemMessage !== null ? <p className="newProblemMessage">{problemMessage}</p> : null
            }
        </div>
    )
}

export default ViewProblem;