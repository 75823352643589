import React, { useState, useEffect, useContext, useRef } from "react";
import "../Styles/Course.css";
import { Context } from "./App";
import UserProfile from "../userData/UserProfile";
import UpdateProfile from "../components/SAMDashComponents/UpdateProfile";
import WeeklySessions from "../components/SAMDashComponents/WeeklySessions";
import UpdateAttendance from "../components/SAMDashComponents/UpdateAttendance";
import FileSharing from "../components/SAMDashComponents/FileSharing";
import UpdateSamSays from "../components/SAMDashComponents/UpdateSamSays";
import ViewAskSAM from "../components/SAMDashComponents/ViewAskSAM";
import PracticeProblems from "../components/SAMandAdvisorComponents/PracticeProblems";
import axios from "axios";
import downArrow from "../assets/downArrow.svg";
import NonWeeklySession from "../components/SAMDashComponents/NonWeeklySession";
import SideNav from "../components/SideNav";
import CancelSession from "../components/SAMDashComponents/CancelSession";
import FullCourseSchedule from "../components/SAMDashComponents/FullCourseSchedule";
function SamDashboard() {
  const [samSchedule, setSamSchedule] = useState([]);
  const [userInfo, setUserInfo] = useContext(Context);
  const [samCurrentCourse, setSamCurrentCourse] = useState(0);
  const [courseSelections, setCourseSelections] = useState();
  const [selectedCourseStudents, setSelectedCourseStudents] = useState([]);
  const [currentSamSays, setCurrentSamSays] = useState(null);
  const [samSaysMessage, setSamSaysMessage] = useState(null);
  const [askSamEntries, setAskSamEntries] = useState(null);
  const [scheduleInfo, setScheduleInfo] = useState(null);
  const isDebounced = useRef(null);
  const lastScroll = useRef(null);
  const findAndScroll = () => {
    if (isDebounced.current) return;
    isDebounced.current = true;
    const sections = document.getElementsByClassName("samDashSection");
    const currentScrollPos = window.scrollY;
    for (let i = 0; i < sections.length; i++) {
      const section = sections[i];
      if (section.offsetTop > currentScrollPos + 50) {
        section.scrollIntoView({ behavior: "smooth" });
        break;
      }
    }

    if (lastScroll.current) {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      lastScroll.current = false;
      document.getElementById("homeDownArrow").classList.remove("invertArrow");
    }

    setTimeout(() => {
      isDebounced.current = false;
    }, 400);
  };
  useEffect(() => {
    getScheduleData();
  }, []);
  //Helper function to get options for course selection select
  const getCourseEntries = (courseSet) => {
    let returnVals = [];
    courseSet.forEach((course) => {
      returnVals.push(
        <option
          onClick={checkSelection}
          value={parseInt(course)}
          id={course}
          key={course}
          className="weeklyCourseSelectionOption"
        >
          CS{course}
        </option>,
      );
    });
    return returnVals;
  };
  const setupScroller = () => {
    document.getElementById("homeDownArrow").classList.remove("notVisible");
    document.getElementById("homeDownArrow").classList.remove("invertArrow");
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
    const handleScroll = () => {
      const sections = document.getElementsByClassName("samDashSection");
      const windowHeight = window.innerHeight;
      const currentScrollPos = window.scrollY + windowHeight;
      for (let i = sections.length - 1; i >= 0; i--) {
        const section = sections[i];
        if (currentScrollPos - 500 >= section.offsetTop) {
          if (i === sections.length - 1) {
            document
              .getElementById("homeDownArrow")
              .classList.add("invertArrow");
            lastScroll.current = true;
          } else if (lastScroll.current) {
            document
              .getElementById("homeDownArrow")
              .classList.remove("invertArrow");
            lastScroll.current = false;
          }
          break;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  };
  //Fetches the student list and SAM Says whenever the selected course is changed
  useEffect(() => {
    setAskSamEntries(null);
    if (samCurrentCourse !== 0) {
      setupScroller();
      let p1 = axios.get(
        `https://api.sam.cs.mtu.edu/api/sam/getStudents/${samCurrentCourse}`,
      );
      let p2 = axios.get(
        `https://api.sam.cs.mtu.edu/api/course/samsays/${samCurrentCourse}`,
      );
      let p3 = axios.get(
        `https://api.sam.cs.mtu.edu/api/sam/fullSchedule/${samCurrentCourse}`,
      );
      fetchAskSam();
      Promise.all([p1, p2, p3]).then((response) => {
        setSelectedCourseStudents(response[0].data.returnArr);
        if (response[1].data.message === undefined) {
          setCurrentSamSays("No SAM Says for this course yet");
        } else {
          setCurrentSamSays(response[1].data.message);
        }
        setScheduleInfo(response[2].data.response[0]);
      });
    } else {
      document.getElementById("homeDownArrow").classList.add("notVisible");
    }
  }, [samCurrentCourse]);

  //Course selection on-click handler
  const checkSelection = (event) => {
    setSamCurrentCourse(parseInt(event.target.value));
  };

  //Makes the selection html for course selection whenever the samSchedule state is altered
  useEffect(() => {
    if (samSchedule.length > 0) {
      for (let i = 0; i < samSchedule.length; i++) {
        if (samSchedule[i] instanceof Set) {
          if (samSchedule[i].size === 1) {
            setSamCurrentCourse(samSchedule[i].entries().next().value[0]);
          } else {
            let returnVals = [];
            returnVals.push(
              <div
                key="courseSelectionWrapper"
                className="weeklySelectionWrapper"
              >
                <h3
                  key="courseSelectionHeader"
                  className="courseSelectionHeader"
                >
                  Select a Course
                </h3>
                <select
                  onChange={checkCourseSelection}
                  defaultValue={parseInt(samCurrentCourse)}
                  className="weeklyCourseSelection"
                >
                  <option
                    key="defaultCourseSelection"
                    value={0}
                    onClick={checkSelection}
                    className="weeklyCourseSelectionOption"
                  >
                    Select a CourseID
                  </option>
                  {getCourseEntries(samSchedule[i])}
                </select>
              </div>,
            );
            setCourseSelections(returnVals);
          }
        }
      }
    }
  }, [samSchedule]);

  const checkCourseSelection = () => {
    const courseSelections = document.getElementsByClassName(
      "weeklyCourseSelectionOption",
    );
    for (let i = 0; i < courseSelections.length; i++) {
      if (courseSelections[i].selected) {
        setSamCurrentCourse(parseInt(courseSelections[i].value));
      }
    }
  };
  //Helper function to get the ask sam data
  const fetchAskSam = () => {
    axios
      .get(`https://api.sam.cs.mtu.edu/api/sam/asksams/${samCurrentCourse}`)
      .then((response) => {
        if (response.data.length !== 0) {
          setAskSamEntries(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Helper function that calls the backend to get scheduling info
  const getScheduleData = () => {
    axios
      .get("https://api.sam.cs.mtu.edu/api/sam/getscheduleinfo")
      .then((response) => {
        let sessionVals = Object.values(response.data[0]); //This only includes non-reoccuring sessions
        let weeklyVals = Object.values(response.data[1]); //This only includes reoccuring sessions
        let courses = Object.values(response.data[2]); //This is a list of all courses the requestor is a SAM for
        let allSessions = Object.values(response.data[3]); //This includes non-reoccuring and reoccuring sessions
        let courseVals = new Set();
        courses.forEach((entry) => courseVals.add(parseInt(entry.courseID)));
        let setArr = [sessionVals, weeklyVals, courseVals, allSessions];
        setSamSchedule(setArr);
      })
      .catch((error) => console.log(error));
  };

  //Helper function to add a weekly sessionList
  const addWeeklySession = (info) => {
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/addWeeklySession", {
        weekday: info[0],
        time: info[1],
        location: info[2],
        courseID: samCurrentCourse,
        endtime: info[3],
      })
      .then(() => {
        getScheduleData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Helper function to update a weekly sessionList
  const updateWeeklySession = (info) => {
    axios
      .patch("https://api.sam.cs.mtu.edu/api/sam/updateWeeklySession", {
        weekday: info[0],
        weeklySessionID: info[3],
        time: info[1],
        location: info[2],
        endtime: info[4],
      })
      .then(() => getScheduleData())
      .catch((error) => console.log(error));
  };

  //Helper function to delete a weekly sessionList
  const deleteWeeklySession = (id) => {
    axios
      .delete(`https://api.sam.cs.mtu.edu/api/sam/deleteWeeklySession/${id}`)
      .then(() => {
        getScheduleData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Helper function to add a sessionList
  const addSession = (sessionInfo) => {
    axios
      .post("https://api.sam.cs.mtu.edu/api/sam/addsession", {
        courseID: samCurrentCourse,
        sessionDate: sessionInfo[0],
        sessionTime: sessionInfo[1],
        sessionLocation: sessionInfo[2],
        sessionNotes: sessionInfo[3],
        endtime: sessionInfo[4],
      })
      .then((response) => {
        getScheduleData();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Helper function to update a sessionList
  const updateSession = (params) => {
    axios
      .patch("https://api.sam.cs.mtu.edu/api/sam/updatesession", {
        date: params[0],
        time: params[1],
        location: params[2],
        notes: params[3],
        endtime: params[4],
        sessionID: params[5],
      })
      .then(() => {
        getScheduleData();
        return 0;
      })
      .catch((error) => {
        console.log(error);
        return 1;
      });
  };

  //Helper function to update sam says
  const updateSamSays = async (newMessage) => {
    try {
      const apiReq = await axios.patch(
        `https://api.sam.cs.mtu.edu/api/sam/updateSamSays/${samCurrentCourse}`,
        { newText: newMessage },
      );
      if (apiReq.status === 200) {
        return "SAM Says has been updated.";
      } else {
        return "Update failed!";
      }
    } catch (error) {
      console.log(error);
    }
  };

  //Helper function to delete a sessionList
  const deleteSession = (sessionID) => {
    axios
      .delete(`https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`)
      .then(() => getScheduleData())
      .catch((error) => console.log(error));
  };

  //Helper function to 'cancel'(delete from db) a specific session
  const cancelSession = (sessionID) => {
    return axios.delete(
      `https://api.sam.cs.mtu.edu/api/sam/deletesession/${sessionID}`,
    );
    //Cancel logic goes here
  };
  //Helper function to get all fields for the SAM Dashboard
  const getClassOptions = (samSchedule) => {
    let sideNavEntries = [
      "Update Profile",
      "Weekly Sesssions",
      "Your Sessions",
      "Update Attendance",
      "SAM Materials",
      "SAM Says",
    ];
    let returnVals = [];
    returnVals.push(
      <div
        className="samDashSection scheduleSection"
        key={`samDashAllSchedule-${samCurrentCourse}`}
      >
        <div className="samDashWeeklyScheduleWrapper">
          <h2 className="samDashSubsubheader">Course Weekly Schedule</h2>
          <FullCourseSchedule
            key={`weeklyScheduleFull-${samCurrentCourse}`}
            courseID={null}
            scheduleData={scheduleInfo}
          />
        </div>
      </div>,
      <div
        key={`samDashWrapperWeekly-${samCurrentCourse}`}
        className="samDashSection scheduleSection"
      >
        <WeeklySessions
          key={`weeklySessionComponent-${samCurrentCourse}`}
          scheduleInfo={[samSchedule[2], samSchedule[1]]}
          weeklySessionAdder={addWeeklySession}
          weeklySessionUpdater={updateWeeklySession}
          weeklySessionDeleter={deleteWeeklySession}
          currentCourse={samCurrentCourse}
          setCurrentCourse={setSamCurrentCourse}
          tableData={scheduleInfo}
        />
        <CancelSession
          key={`cancelSessionComponent-${samCurrentCourse}`}
          sessionList={samSchedule[3]}
          cancelFunction={cancelSession}
        />
        <NonWeeklySession
          addFunction={addSession}
          key={`updateSessionComponent-${samCurrentCourse}`}
          deleteFunction={deleteSession}
          updateFunction={updateSession}
          sessionList={samSchedule[0]}
          selectedCourseID={samCurrentCourse}
        />
      </div>,
      <div
        key={`samDashWrapperUpdateAtten-${samCurrentCourse}`}
        className="samDashSection"
      >
        <UpdateAttendance
          key={`updateAttendance-${samCurrentCourse}`}
          sessions={samSchedule[3]}
          selectedCourse={samCurrentCourse}
          studentList={selectedCourseStudents}
        />
      </div>,
      <div
        key={`samDashWrapperFileShare-${samCurrentCourse}`}
        className="samDashSection"
      >
        <FileSharing
          key={`samMaterials-${samCurrentCourse}`}
          courseID={samCurrentCourse}
        />
      </div>,
      <div
        key={`samDashWrapperSamSAYS-${samCurrentCourse}`}
        className="samDashSection"
      >
        <UpdateSamSays
          key={`updateSamSays-${samCurrentCourse}`}
          currentSamSays={currentSamSays}
          updateFunction={updateSamSays}
          defaultMessage={samSaysMessage}
        />
      </div>,
    );
    if (askSamEntries !== null)
      returnVals.push(
        <div
          key={`samDashWrapperAskSAM-${samCurrentCourse}`}
          className="samDashSection"
        >
          <ViewAskSAM
            key={`viewAskSAM-${samCurrentCourse}`}
            entries={askSamEntries}
          />
        </div>,
      );
    if (askSamEntries !== null) sideNavEntries.push("Ask SAMs");
    sideNavEntries.push("Practice Problems");
    returnVals.push(
      <div
        key={`samDashWrapperPracticeProblem-${samCurrentCourse}`}
        className="samDashSection"
      >
        <PracticeProblems
          selectedCourse={samCurrentCourse}
          whom={"sam"}
          key={`practiceProblems-${samCurrentCourse}`}
        />
      </div>,
    );

    returnVals.push(
      <SideNav
        key={`sideNavFor-${samCurrentCourse}`}
        navItemsMap={sideNavEntries}
        scrollFunction={scrollToSection}
        currentCourse={samCurrentCourse}
        updateCourse={sideNavCourseChange}
        courseList={samSchedule[2]}
      />,
    );
    return returnVals;
  };
  const scrollToSection = (sectionIndex) => {
    document
      .getElementsByClassName("samDashSection")
      [sectionIndex].scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const sideNavCourseChange = (newCourseID) => {
    setSamCurrentCourse(parseInt(newCourseID));
  };
  const getWelcome = () => {
    let returnSet = [];
    returnSet.push(
      <div key={`samDashWelcomeWrapper`} className="welcomeWrapper">
        <h1 className="welcomeMessage">Welcome Back {UserProfile.getName()}</h1>
      </div>,
    );
    return returnSet;
  };
  return (
    <div className="samDashboardWrapper">
      {!userInfo.Signin ? <Navigate to="/login" /> : getWelcome()}
      {
        //Loading in course selections
        courseSelections !== undefined || samCurrentCourse !== 0 ? (
          <div className="samDashSection">
            <>
              <UpdateProfile />
              {samCurrentCourse === 0 ? courseSelections : null}
            </>
          </div>
        ) : samCurrentCourse === 0 ? (
          <h2 className="loadingMessage">Loading...</h2>
        ) : null
      }
      {
        //Loading in all other fields once a course is selected
        samCurrentCourse !== 0 &&
        samSchedule !== undefined &&
        scheduleInfo !== null
          ? getClassOptions(samSchedule)
          : null
      }
      <img
        src={downArrow}
        id="homeDownArrow"
        className="notVisible"
        onClick={findAndScroll}
        draggable={false}
      />
    </div>
  );
}

export default SamDashboard;
