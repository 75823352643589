import React, { useState, useEffect } from 'react';

class Session {
    constructor(time, location, endtime) {
        this.time = time;
        this.location = location;
        this.sam = [];
        this.endtime = endtime;
    }
    addSam(sam) {
        if (!this.sam.includes(sam)) {
            this.sam.push(sam);
        }
    }
}

const CourseSchedule = ({ courseID, scheduleData }) => {
    const [sessionsByWeekday, setSessionsByWeekday] = useState({});

    // Determines if two sessions are at the same time and location and merges them into one
    const addOrMergeSession = (weekday, time, location, sam, endTime) => {
        setSessionsByWeekday((prevSessions) => {
            const weekdaySession = prevSessions[weekday] || [];
            const existingSession = weekdaySession.find(
                (s) => s.time === getFormattedTime(time) && s.location === location
            );

            if (existingSession) {
                // If session exists, add the instructor to it
                existingSession.addSam(sam);
            } else {
                // Create a new session
                const newSession = new Session(
                    getFormattedTime(time),
                    location,
                    getFormattedTime(endTime)
                );
                newSession.addSam(sam);
                weekdaySession.push(newSession);
            }

            // Return the updated state with the new or updated session
            return {
                ...prevSessions,
                [weekday]: weekdaySession,
            };
        });
    };

    // Updates the session information whenever the schedule data is altered
    useEffect(() => {
        if (scheduleData.length > 0) {
            scheduleData.forEach((session) => {
                addOrMergeSession(
                    session.weekday,
                    session.sessionTime,
                    session.sessionLocation,
                    session.fullName,
                    session.endTime
                );
            });
        }
    }, [scheduleData]);

    // Helper function to format time from 24-hour to 12-hour format
    function getFormattedTime(time) {
        let [hours, minutes] = time.split(":");
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;
        return `${hours}:${minutes} ${ampm}`;
    }

    // Log the sessions to verify state is updated correctly
    console.log("Sessions by Weekday:", sessionsByWeekday);

    return (
        <div className="row justify-content-center">
            {Object.entries(sessionsByWeekday).map(([weekday, sessions], index) => (
                <div className="col-lg-3 col-md-6" key={index}>
                    <div className="services-7">
                        <h4 className="title">{weekday}</h4>
                        {sessions.map((session, i) => (
                            <div key={i} style={{ marginBottom: '20px' }}>
                                <p><strong>Time:</strong> {session.time} - {session.endtime}</p>
                                <p><strong>Location:</strong> {session.location}</p>
                                <p><strong>Instructors:</strong> {session.sam.join(', ')}</p>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CourseSchedule;
