import { Outlet, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import GoogleOneTap from "../components/authComponents/GoogleOneTap";
import { googleLogout } from "@react-oauth/google";
import UserProfile from "../userData/UserProfile";
import SignoutWarning from "../components/SignoutWarning";
import Nav from "./Nav";
import axios from "axios";
import Footer from "../components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import '../assets/css/font-awesome.min.css';
import '../assets/css/flaticon.css';
import '../assets/css/animate.css';
import '../assets/css/owl.carousel.css';
import '../assets/css/owl.theme.css';
import '../assets/css/owl.transitions.css';
import '../assets/css/meanmenu.min.css';
import '../assets/css/lightbox.min.css';
import '../assets/css/magnific-popup.css';
import '../assets/css/typography.css';
import '../assets/css/shortcode.css';
import '../assets/style.css';
import '../assets/css/color.css';
import '../assets/css/responsive.css';
// Ensure jQuery is globally available
export const Context = React.createContext();
export const Timeout = React.createContext();
export const Fields = React.createContext();
const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState({
    //State that holds user info
    Roles: UserProfile.getRoles(),
    Signin: UserProfile.isLoggedIn(),
  });
  const [loading, setLoading] = useState(true); //Initial state to avoid flickers in nav conditional renders
  const [oneTap, setOneTap] = useState(false); //State to determine whether one-tap should be dispalyed
  const [sessionEnd, setSessionEnd] = useState(null); //State to mark when the session ends
  const [warningMessage, setWarningMessage] = useState(null);
  const [navFields, setNavFields] = useState(null);
  const warningTimeoutRef = useRef(null);
  const signoutTimeoutRef = useRef(null);
  const timerWentOff = useRef(null);

  //Helper function that removes all the user profile info, resets all states, initiates google logout, and redirects to the home page
  const handleLogout = () => {
    localStorage.clear("sessionExpiration");
    axios.get("https://api.sam.cs.mtu.edu/api/auth/logout").then((response) => {
      //All logic is done inside the of the then so that the promise resolves before the rest of the work is done
      UserProfile.logOut();
      setUserInfo({
        Roles: {
          Student: false,
          SAM: false,
          Advisor: false,
          Admin: false,
        },
        Signin: false,
      });
      googleLogout();
      setOneTap(true);
      setSessionEnd(null);
      clearTimeout(signoutTimeoutRef.current);
      clearTimeout(warningTimeoutRef.current);
      if (location.pathname === "/login") {
        return;
      }
      navigate("/");
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  //Helper function for manual logouts
  const manualLogout = () => {
    handleLogout();
    clearTimeout(warningTimeoutRef.current);
    clearTimeout(signoutTimeoutRef.current);
    setWarningMessage(null);
  };

  //Setting timers to alert the user of their session ending and end their session once the 2 hours has passed
  useEffect(() => {
    checkTimers();
  }, []);

  //Checking if the session expiration timers need to be set whenever session end changes
  useEffect(() => {
    if (sessionEnd !== null) {
      if (!localStorage.getItem("sessionExpiration")) {
        localStorage.setItem("sessionExpiration", sessionEnd);
      }
      checkTimers();
    } else {
      Promise.resolve(userInfo.Signin).then((response) => {
        if (response === true) {
          if (localStorage.getItem("sessionExpiration")) {
            setSessionEnd(localStorage.getItem("sessionExpiration"));
            checkTimers();
          } else {
            manualLogout();
          }
        }
      });
    }
  }, [sessionEnd]);

  //Helper function to check/add in the warning/signout timers
  const checkTimers = () => {
    const sessionExpiration = localStorage.getItem("sessionExpiration");
    if (sessionExpiration !== null) {
      clearTimeout(warningTimeoutRef.current);
      clearTimeout(signoutTimeoutRef.current);
      const currentTime = new Date().getTime();
      const timeoutDuration = sessionExpiration - currentTime;
      if (timeoutDuration < 0) {
        handleLogout();
        return;
      }
      const warningTime = 5 * 60 * 1000; //5mins in miliseconds
      if (timeoutDuration - warningTime < 0 && timerWentOff.current !== true) {
        setWarningMessage(
          `You will be signed out in ${Math.round(Math.abs((timeoutDuration - warningTime) / 1000 / 60))}  minutes`,
        );
      } else {
        warningTimeoutRef.current = setTimeout(() => {
          setWarningMessage("You will be signed out in 5 minutes");
          timerWentOff.current = true;
        }, timeoutDuration - warningTime);
      }

      signoutTimeoutRef.current = setTimeout(() => {
        setWarningMessage("You will be signed out in 5 seconds");
        setTimeout(() => {
          handleLogout();
          setWarningMessage(null);
        }, 5000);
      }, timeoutDuration);
      return () => {
        clearTimeout(warningTimeoutRef.current);
        clearTimeout(signoutTimeoutRef.current);
      };
    }
  };

  //Rechecking the user data on each load to determine session information
  useEffect(() => {
    const refetchInfo = async () => {
      let newInfo = {
        Roles: UserProfile.getRoles(),
        Signin: await UserProfile.isLoggedIn(),
      };
      return newInfo;
    };
    refetchInfo().then((newInfo) => {
      setUserInfo(newInfo);
      setLoading(false);
    });
  }, []);

  //Login handler that verifies their account with google, checks their session with the API, and then gets their roles from the Database
  const success = (codeResponse) => {
    axios
      .post(
        "https://api.sam.cs.mtu.edu/api/auth/google",
        {
          credential: codeResponse["credential"],
        },
        { withCredentials: true },
      )
      .then((response) => {
        setSessionEnd(new Date(response.data.expiresAt).getTime());
        const name = response.data.name;
        UserProfile.registerUser(name);
      })
      .then(() => {
        axios
          .get("https://api.sam.cs.mtu.edu/api/auth/check-session")
          .then((response) => {
            axios
              .get("https://api.sam.cs.mtu.edu/api/auth/getroles")
              .then((response) => {
                const roleBits = response.data[0][0].Roles;
                const digits = roleBits.toString().split("");
                let adminStatus = parseInt(digits[0]) === 1;
                let samStatus = parseInt(digits[1]) === 1;
                let advisorStatus = parseInt(digits[2]) === 1;
                let studentStatus = parseInt(digits[3]) === 1;
                UserProfile.setRoles({
                  Student: studentStatus,
                  SAM: samStatus,
                  Advisor: advisorStatus,
                  Admin: adminStatus,
                });
                setUserInfo({
                  Roles: {
                    Student: studentStatus,
                    SAM: samStatus,
                    Advisor: advisorStatus,
                    Admin: adminStatus,
                  },
                  Signin: true,
                });
                checkRedirect();
              });
          })
          .catch((error) => console.error("Error:", error));
      })
      .catch((error) => {
        setWarningMessage(
          "Login Failed! Please make sure to sign in with a @mtu.edu gmail account",
        );
      });
  };
  //Helper function to determine where to redirect a user based on their roles
  const checkRedirect = () => {
    let roles = UserProfile.getRoles();
    if (roles !== undefined) {
      if (roles.Student) {
        navigate("/account");
        return;
      }
      if (roles.SAM) {
        navigate("/sam");
        return;
      }
      if (roles.Advisor) {
        navigate("/advisor");
        return;
      }
      navigate("/advisor");
      if (!roles.Student && !roles.SAM && !roles.Advisor) {
        navigate("/");
        return;
      }
    }
  };
  return (
    <Context.Provider value={[userInfo, setUserInfo]}>
      <Timeout.Provider value={[sessionEnd, setSessionEnd]}>
        {warningMessage !== null ? (
          <SignoutWarning
            message={warningMessage}
            dismissWarning={setWarningMessage}
          />
        ) : null}
        <div className="fullWrapper">
          {oneTap ? <GoogleOneTap success={success} /> : null}
          {loading === false ? (
            <>
              <Fields.Provider value={[navFields, setNavFields]}> 
                <Nav logoutFunction={manualLogout} />
                <Outlet />
                <Footer />
              </Fields.Provider>
            </>
          ) : (
            <div id="loader-overflow">
              <div id="loader3" className="loader-cont">Please enable JS</div>
            </div>
          )}
        </div>
      </Timeout.Provider>
    </Context.Provider>
  );
};

export default App;
