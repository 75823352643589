import React from "react";
import '../Styles/root.css';
function MultiSelect({entries, selections}){
    //Onkeydown handler to check if the user presses enter and selects/deselects the top item in the list of results
    const search = (event) => {
        if (event.key === 'Enter') {
            const options = document.getElementsByClassName(`studentOption`);
            const text = (document.getElementById(`multiSelectSearch`).value).toLowerCase();
            for (let item of options) {
                if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
                    document.getElementById(`multiSelectSearch`).value = "";
                    if(selections.current.includes(item.id)){
                        selections.current = selections.current.filter(item=> item!==item.id);
                        item.classList.remove("studentOptionSelected");
                    }else{
                        selections.current.push(item.id);
                        item.classList.add("studentOptionSelected");
                    }
            }

        }
    }
}

    //Onkeyup handler to update the shown fields as the user searches
    const filterFunction = () => {
        const options = document.getElementsByClassName(`studentOption`);
        const text = (document.getElementById(`multiSelectSearch`).value).toLowerCase();
        for (let item of options) {
            if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
                item.style.display = "";
            } else {
                item.style.display = "none";
            }
        }
    }
    //Onclick event handler to add/remove the selection and update the classlist so the styling reflects the selection change
    const toggleSelection = (event)=>{
        if(selections.current.includes(event.target.id)){
            selections.current = selections.current.filter(item=> item!==event.target.id);
            event.target.classList.remove("studentOptionSelected");
        }else{
            selections.current.push(event.target.id);
            event.target.classList.add("studentOptionSelected");
        }
    }

    return(
        <div className="multiSelectWrapper">
            <input type="text" id="multiSelectSearch" onKeyUp={filterFunction} onKeyDown={search} placeholder="Search Students Name"/>
            <div id="multiSelectOptionsWrapper">
                <ul id="multiSelectList">
                    {entries.map((entry)=> <li key={`attendanceSelectEntry-${entry[0]}`} id={entry[1]} onClick={toggleSelection} className={selections.current.includes(entry[1])?"studentOption studentOptionSelected":"studentOption"}>{entry[0]}</li>)}
                </ul>
            </div>
        </div>
    )
}

export default MultiSelect;