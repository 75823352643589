import React from "react";
import { useState, useEffect, useRef } from "react";
function CancelSession({ sessionList, cancelFunction }) {
  const [sessionMessage, setSessionMessage] = useState(null);
  const clearMessage = useRef(null);
  useEffect(() => {
    if (sessionMessage !== null) {
      clearTimeout(clearMessage.current);
      clearMessage.current = setTimeout(() => {
        setSessionMessage(null);
      }, 5000);
    }
  }, [sessionMessage]);
  const getSessions = () => {
    let returnSeshs = sessionList.map((session) => {
      if (session.weeklySessionID !== null)
        return (
          <option
            key={`cancelSessionOption-${session.sessionID}`}
            className="cancelSessionOption"
            value={session.sessionID}
          >
            {session.Date}
          </option>
        );
    });
    return returnSeshs;
  };
  const checkSelection = () => {
    const options = document.getElementsByClassName("cancelSessionOption");
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        if (parseInt(options[i].value) === 0) {
          setSessionMessage("Please select a valid session");
        } else {
          setSessionMessage("Processing...");
          cancelFunction(options[i].value)
            .then(() => {
              setSessionMessage("Session Deleted!");
              options[i].remove();
            })
            .catch(() => setSessionMessage("Deletion failed!"));
        }
      }
    }
  };
  return (
    <div className="cancelSessionWrapper">
      <h2 className="samDashSubsubheader">Cancel Weekly Session</h2>
      {sessionList !== null && sessionList !== undefined ? (
        <select className="cancelSessionsSelect" defaultValue={0}>
          <option className="cancelSessionOption" value={0}>
            Please select a session
          </option>
          {getSessions()}
        </select>
      ) : (
        <p className="loadingMessage">Loading...</p>
      )}
      <button id="cancelSessionButton" onClick={checkSelection}>
        Cancel Session
      </button>
      <p className="sessionMessagePara">{sessionMessage}</p>
    </div>
  );
}

export default CancelSession;
