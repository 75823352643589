import React from 'react';
import { useEffect, useState } from 'react';
import axios from 'axios';
import '../../Styles/Course.css';
import UploadMaterial from '../fileSharingComponents/UploadMaterial';
import DownloadMaterial from '../fileSharingComponents/DownloadMaterial';
const classNames = {
    2311: "Discrete Structures", 2321: "Data Structures",
    3331: "Concurrent Computing", 3411: "Systems Programming"
};

function FileSharing({ courseID }) {
    const [uploadMessage, setUploadMessage] = useState(null);
    const [materialList, setMaterialList] = useState(null);

    //Fetching the files on courseID change
    useEffect(() => {
        fetchFiles();
    }, [courseID])

    //Helper function to query api for materials to view
    const fetchFiles = () => {
        axios.get(`https://api.sam.cs.mtu.edu/api/sam/materials/view/${courseID}`, {
            withCredentials: true
        }).then((response) => {
            let matMap = new Map();
            for(const entry of response.data.returnArr){
                matMap.set(entry.materialID,{
                    fileLocation: entry.fileLocation,
                    name: entry.materialName,
                    postTime: entry.uploadTime,
                    comments: entry.comments
                })
            }
            setMaterialList(matMap);
        }).catch(error => console.log(error));
    }

    //Helper function to upload new materials
    const uploadPost = (form) => {
        axios.post(`https://api.sam.cs.mtu.edu/api/sam/materials/upload/${courseID}`,form,{
            withCredentials: true,
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(()=>{
            fetchFiles();
            setUploadMessage("Upload Successful");
        }).catch(error=>{
            setUploadMessage("Upload Failed!");
            console.log(error);
        })
    }
    return (
        <div className="fileSharingWrapper">
            <DownloadMaterial courseID={courseID} entries={materialList} />
            <UploadMaterial courseID={courseID} upload={uploadPost} message={uploadMessage} setMessage={setUploadMessage} />
        </div>
    );
}

export default FileSharing;
