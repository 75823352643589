import React from "react";
import check from "../../assets/check.svg";
function StudentEntry({
  studentName,
  studentUsername,
  bonusPointCount,
  updatePoints,
}) {
  const updateStudentPoints = () => {
    const username = studentUsername;
    const bonusPoints = parseInt(
      document.getElementById(`bonusPointsNumberInput-${username}`).value,
    );
    updatePoints(username, bonusPoints);
  };
  const checkNumber = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      event.target.value = 0;
    }
  };
  return (
    <div className="studentEntryWrapper">
      <div className="studentNameWrapper">{studentName}</div>
      <div className="bonusPointsWrapper">
        <span className="bonusPointsTextWrapper">Bonus Points:</span>
        <input
          type="number"
          id={`bonusPointsNumberInput-${studentUsername}`}
          defaultValue={bonusPointCount}
          min={0}
          step={1}
          onInput={checkNumber}
        />
        <img
          className="updatePointsCheck"
          src={check}
          alt="Submit"
          onClick={updateStudentPoints}
        />
      </div>
    </div>
  );
}

export default StudentEntry;
