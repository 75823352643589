import React from 'react';
import '../Styles/root.css';
import { useState, useRef, useEffect } from 'react';
function SearchBar({ name, entries, placeholderText, selections, setState, selectState, isMulti }) {
    const [selectedOptions, setSelectionOptions] = useState(selections!==null?selections.current:null);
    const dropdownRef = useRef(null);

    //Helper function to select the next option when a user presses enter while searching
    const search = (event) => {
        if (event.key === 'Enter') {
            const options = document.getElementsByClassName(`searchDropdownOption+${name}`);
            const text = (document.getElementById(`searchBarInput-${name}`).value).toLowerCase();
            for (let item of options) {
                if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
                    document.getElementById(`searchBarInput-${name}`).value = "";
                    if (isMulti) {
                        item.selected = !item.selected
                        toggleSelection(item.value);
                        break;
                    }else{
                        setState(item.value);
                        break;
                    }
                }
            }
        }

    }
    
    //Helper function to toggle a select on or off- only used if 'isMulti' is true
    const toggleSelection = (input) => {
        if (selections.current.indexOf(input) === -1) {
            selections.current.push(input);
        } else {
            selections.current.splice(selections.current.indexOf(input), 1);
        }
        setSelectionOptions(selections.current);
    }

    //Helper function to display only options that have the specified characters a user is searching
    const filterFunction = () => {
        const options = document.getElementsByClassName(`searchDropdownOption+${name}`);
        const text = (document.getElementById(`searchBarInput-${name}`).value).toLowerCase();
        for (let item of options) {
            if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
                item.style.display = "";
            } else {
                item.selected = false;
                item.style.display = "none";
            }
        }
        for (let item of options) {
            if ((item.innerHTML).toLowerCase().indexOf(text) > -1) {
                item.selected = true;
                return;
            }
        }
    }

    //Onclick handler to either toggle or select an option depending on if the selection ismulti or not
    const clickHandler = (event) => {
        if (isMulti) toggleSelection(event.target.value);
        if (!isMulti) setState(parseInt(event.target.value));

    }

    //Helper function to try to smooth out scrolling on selection
    const savePos = (event) => {
        dropdownRef.current.scrollTop = event.target.scrollTop;
    }

    //Helper function to generate entries for the select field
    const getEntries = () => {
        let returnArr = [];
        if (!isMulti) returnArr.push(<option className={`searchDropdownOption ${name}`} value={0} key={`searchDropdownOption-default`}>{placeholderText}</option>)
        entries.forEach(entry => {
            returnArr.push(
                <option className={`searchDropdownOption ${name}`} fullname={entry[0]} value={entry[1]} key={`searchDropdownOption-${entry[1]}`}>{entry[0]}</option>
            )
        })
        return returnArr;
    }

    return (
        <div className="searchBarWrapper">
            <input type="text" className='searchBarTextInput' placeholder={placeholderText} id={`searchBarInput-${name}`} onKeyUp={filterFunction} onKeyDown={search} />
            {isMulti ? <select ref={dropdownRef} id="searchSelect" key={`dropdownSelectionWith${selectedOptions.length}`} multiple value={selectedOptions} onChange={clickHandler} onScroll={savePos} className="dropdownSelect">
                {entries !== undefined ? getEntries() : null}
            </select> : <select ref={dropdownRef} id="searchSelect" key={`dropdownSelectionWith-${name}`} value={selectState} onChange={clickHandler} onScroll={savePos} className="dropdownSelect">
                {entries !== undefined ? getEntries() : null},
            </select>}

        </div>
    );
}

export default SearchBar;
