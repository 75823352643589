import React, { useState, useEffect } from "react";
import "../../Styles/Course.css";
//Session class template to easier reuse
class Session {
  constructor(time, location, endtime) {
    this.time = time;
    this.location = location;
    this.sam = [];
    this.endtime = endtime;
  }
  addSam(sam) {
    if (!this.sam.includes(sam)) {
      this.sam.push(sam);
    }
  }
}
const preset = {
  Monday: [],
  Tuesday: [],
  Wednesday: [],
  Thursday: [],
  Friday: [],
  Saturday: [],
  Sunday: [],
};
function FullCourseSchedule({ courseID, scheduleData }) {
  const [sessionNote, setSessionNote] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [sessionsByWeekday, setSessionsByWeekday] = useState(
    structuredClone(preset),
  );
  //Helper function to format the time into a readable manner
  function getFormattedTime(time) {
    let [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  }
  //Determines if two sessions are at the same time and location and merges them into one
  const addOrMergeSession = (map, weekday, time, location, sam, endtime) => {
    const weekdaySession = map[weekday];
    const session = weekdaySession.find(
      (s) => s.time === getFormattedTime(time) && s.location === location,
    );
    if (session) {
      session.addSam(sam);
    } else {
      const newSession = new Session(
        getFormattedTime(time),
        location,
        getFormattedTime(endtime),
      );
      newSession.addSam(sam);
      weekdaySession.push(newSession);
    }
    return map;
  };
  useEffect(() => {
    setSessionsByWeekday(structuredClone(preset));
  }, [courseID]);
  const regenMap = () => {
    let newSessionsByWeekday = structuredClone(preset);
    scheduleData.forEach((session) => {
      newSessionsByWeekday = addOrMergeSession(
        newSessionsByWeekday,
        session.weekday,
        session.sessionTime,
        session.sessionLocation,
        session.fullName,
        session.endTime,
      );
    });
    setSessionsByWeekday(newSessionsByWeekday);
  };
  //Updates the session information whenever the schedule data is altered
  useEffect(() => {
    if (scheduleData === undefined || null) return;
    regenMap();
    setSessionNote(null);
  }, [scheduleData]);
  const updateDay = () => {
    if (selectedDay === event.target.value) {
      event.target.classList.toggle("selectedScheduleDay");
      setSelectedDay(null);
    } else {
      if (sessionsByWeekday[event.target.value].length > 0) {
        toggleDay(selectedDay);
        setSelectedDay(event.target.value);
      }
    }
  };
  const toggleDay = (weekday) => {
    let options = document.getElementsByClassName("samCourseScheduleDay");
    for (let i = 0; i < options.length; i++) {
      let currentSelection = options[i];
      if (currentSelection.value === weekday) {
        currentSelection.classList.toggle("selectedScheduleDay");
      }
    }
  };
  useEffect(() => {
    if (selectedDay !== null) {
      toggleDay(selectedDay);
    }
  }, [selectedDay]);
  //Helper function to return the session information for a given day
  const renderSessionsForDay = (day) => {
    return sessionsByWeekday[day].map((session, index) => (
      <div
        key={`dayInfoWrapper-${day}-${index}`}
        className="sessionScheduleDayWrapper"
      >
        <p className="courseScheduleInfoElement">
          <span className="boldInline">Time:</span> {session.time} to{" "}
          {session.endtime}
        </p>
        <p className="courseScheduleInfoElement">
          <span className="boldInline">Location:</span> {session.location}
        </p>
        <p className="courseScheduleInfoElement">
          <span className="boldInline">SAMs:</span> {session.sam.join(", ")}
        </p>
      </div>
    ));
  };
  return (
    <div
      id={`samCourseScheduleWrapper`}
      className={`${selectedDay !== null ? "withDaySelectedWrapper" : ""}`}
    >
      <div className="samCourseScheduleTopSection">
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Sunday"
            id="samCourseScheduleSunday"
            className="samCourseScheduleDay"
          >
            Su
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Sunday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Monday"
            id="samCourseScheduleMonday"
            className="samCourseScheduleDay"
          >
            M
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Monday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Tuesday"
            id="samCourseScheduleTuesday"
            className="samCourseScheduleDay"
          >
            T
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Tuesday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Wednesday"
            id="samCourseScheduleWednesday"
            className="samCourseScheduleDay"
          >
            W
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Wednesday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Thursday"
            id="samCourseScheduleThursday"
            className="samCourseScheduleDay"
          >
            Th
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Thursday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Friday"
            id="samCourseScheduleFriday"
            className="samCourseScheduleDay"
          >
            F
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Friday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
        <div className="courseScheduleCardWrapper">
          <button
            onClick={updateDay}
            value="Saturday"
            id="samCourseScheduleSaturday"
            className="samCourseScheduleDay"
          >
            S
          </button>
          <span
            className={`scheduleDot ${sessionsByWeekday.Saturday.length > 0 ? "hasSessions" : ""}`}
          ></span>
        </div>
      </div>
      {selectedDay !== null ? (
        <div className="samScheduleDayInfo">
          {renderSessionsForDay(selectedDay)}
        </div>
      ) : null}
    </div>
  );
}
export default FullCourseSchedule;
