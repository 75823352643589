import React from 'react';
import photo from "../assets/homepagePhotos/photo-10.jpg";
import Typewriter from "typewriter-effect";

const CourseBanner = ({ samSays }) => {
    return (
        <div style={{ position: 'relative' }}>
            {/* Background Image */}
            <img
                className="d-block w-100"
                src={photo}
                alt="image"
                style={{ height: '30vh', objectFit: 'cover' }}
            />
            {/* Dark Overlay */}
            <div
                className="image-overlay"
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                }}
            />
            {/* Centered Text */}
            {samSays.length > 0 ? 
            <div
                className="centered-text samText"
                style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    textAlign: 'center',
                }}
            >
                <h2>
                    <Typewriter
                        onInit={(typewriter) => {
                            typewriter
                                .typeString("SAM Says!")
                                .pauseFor(1000)
                                .deleteAll()
                                .typeString(`"${samSays[0]?.message}"`)
                                .start();
                        }}
                    />
                </h2>
                <p>- {samSays[0]?.whom}</p>
            </div> : ''}
        </div>
    );
};

export default CourseBanner;
