import React from "react";
import { useState, useEffect } from "react";
import edit from "../../assets/edit.svg";
import deleteIcon from "../../assets/delete.svg";
import check from "../../assets/check.svg";
import cancel from "../../assets/cancel.svg";
function NonWeeklySession({
  sessionList,
  deleteFunction,
  updateFunction,
  addFunction,
  selectedCourseID,
}) {
  const [selectedSession, setSelectedSession] = useState(0);
  const [formattedSessionList, setFormattedSessionList] = useState(null);
  const [message, setMessage] = useState(null);
  useEffect(() => {
    if (message !== null) {
      setTimeout(() => {
        setMessage(null);
      }, 10000);
    }
  }, [message]);

  useEffect(() => {
    updateSessionList();
  }, [sessionList, selectedSession]);

  //Helper function to get time in readable format
  const getFormattedTime = (time) => {
    let [hours, minutes] = time.split(":");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const getFormattedDate = (dateString) => {
    return new Date(dateString).toLocaleString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };
  const updateSessionList = () => {
    let returnSet = [
      Object.entries(sessionList).map((entry) => {
        if (entry[1].courseID === parseInt(selectedCourseID)) {
          let selected = false;
          if (entry[1].sessionID === selectedSession) selected = true;
          return (
            <li
              key={`nonReoccuringSeshLi-${entry[1].sessionID}`}
              className="nonReoccuringSessionEntry"
            >
              <p
                className={`nonWeeklySessionImmutable ${selected ? "doNotShow" : ""}`}
              >
                {`${getFormattedDate(`${entry[1].sessionDate.slice(0, 10)}T${entry[1].sessionTime}`)}`}
                &nbsp;to&nbsp;
                {`${getFormattedTime(entry[1].endTime)}`}
                &nbsp;in&nbsp;
                {`${entry[1].sessionLocation}`}
                &nbsp;
                {`${
                  entry[1].notes !== null && entry[1].notes !== "null"
                    ? `Notes: ${entry[1].notes}`
                    : "No Notes"
                }`}
              </p>
              <span
                key={`nonReoccuringSeshSpan1-${entry[1].sessionID}`}
                className={`nonReoccuringSessionText ${selected ? "" : "doNotShow"}`}
              >
                <span className="innerDivLeftSide">
                  <input
                    className={`nonReoccuringDatetime ${!selected ? "notEditing" : null}`}
                    disabled={selected === false}
                    key={`updateSessionDatetimeKey-${entry[1].sessionID}`}
                    type="datetime-local"
                    id={`updateSessionDatetime-${entry[1].sessionID}`}
                    defaultValue={`${entry[1].sessionDate.slice(0, 10)}T${entry[1].sessionTime}`}
                  />
                  <p className="nonReoccuringInnerText">&nbsp;to&nbsp;</p>
                  <input
                    className={`nonReoccuringDatetime ${!selected ? "notEditing" : null}`}
                    disabled={selected === false}
                    key={`updateSessionEndTime-${entry[1].sessionID}`}
                    type="time"
                    id={`updateSessionEndTime-${entry[1].sessionID}`}
                    defaultValue={entry[1].endTime.slice(0, 5)}
                  />
                  <p className="nonReoccuringInnerText">&nbsp;in&nbsp;</p>
                  <input
                    className={`nonReoccuringLocation ${!selected ? "notEditing" : null}`}
                    disabled={selected === false}
                    key={`updateSessionLocationKey-${entry[1].sessionID}`}
                    type="text"
                    placeholder="Location"
                    id={`updateSessionLocation-${entry[1].sessionID}`}
                    defaultValue={entry[1].sessionLocation}
                  />
                </span>
                <span
                  className={`innerDivRightSide ${!selected ? "notEditing" : ""}`}
                >
                  {!selected ? (
                    entry[1].notes !== null && entry[1].notes !== "null" ? (
                      <p className="nonReoccuringInnerText notesText">
                        Notes:&nbsp;
                        <span className="innerTextNotesContent">
                          {entry[1].notes}
                        </span>
                      </p>
                    ) : (
                      <p className="nonReoccuringInnerText">No Notes</p>
                    )
                  ) : null}
                  <input
                    className={`nonReoccuringNotes ${!selected ? "notEditing" : ""} ${entry[1].notes !== null && entry[1].notes !== "null" ? "" : "noNotes"}`}
                    disabled={selected === false}
                    key={`updateSessionNotes-${entry[1].sessionID}`}
                    type="text"
                    id={`updateSessionNotes-${entry[1].sessionID}`}
                    defaultValue={
                      entry[1].notes !== null &&
                      entry[1].notes !== "null" &&
                      selected
                        ? entry[1].notes
                        : ""
                    }
                    placeholder="Notes"
                  />
                </span>
              </span>
              <span
                key={`nonReoccuringSeshSpan2-${entry[1].sessionID}`}
                className="nonReoccuringSessionSymbols"
              >
                <img
                  draggable={false}
                  onClick={() => {
                    if (!selected) {
                      setSelectedSession(
                        parseInt(event.target.getAttribute("value")),
                      );
                    } else {
                      updateSession();
                    }
                  }}
                  value={entry[1].sessionID}
                  src={!selected ? edit : check}
                  className="editIcon"
                ></img>
                <img
                  draggable={false}
                  onClick={cancelOrDelete}
                  src={!selected ? deleteIcon : cancel}
                  value={entry[1].sessionID}
                  className="deleteIcon"
                ></img>
              </span>
            </li>
          );
        }
      }),
    ];
    if (selectedSession === -1) {
      returnSet.push(
        <li
          key={`nonReoccuringSeshLi-new`}
          className="nonReoccuringSessionEntry newEntry"
        >
          <span
            key={`nonReoccuringSeshSpan1-new`}
            className="nonReoccuringSessionText"
          >
            <input
              className={`nonReoccuringDatetime`}
              key={`updateSessionDatetimeKey-new`}
              type="datetime-local"
              id={`updateSessionDatetime-new`}
            />
            &nbsp;{"to"}&nbsp;
            <input
              className={`nonReoccuringEndtime`}
              key={`updateSessionEndtime-new`}
              type="time"
              id={`updateSessionEndtime-new`}
            />
            <input
              className={`nonReoccuringLocation`}
              key={`updateSessionLocationKey-new`}
              type="text"
              placeholder="Location"
              id={`updateSessionLocation-new`}
            />
            <input
              className={`nonReoccuringNote`}
              key={`updateSessionNotes-new`}
              type="text"
              id={`updateSessionNotes-new`}
              placeholder="Notes Optional"
            />
          </span>
          <span
            key={`nonReoccuringSeshSpan2-new`}
            className="nonReoccuringSessionSymbols"
          >
            <img onClick={addNewSession} src={check} className="editIcon"></img>
            <img
              onClick={() => setSelectedSession(0)}
              src={cancel}
              className="deleteIcon"
            ></img>
          </span>
        </li>,
      );
    } else {
      returnSet.push(
        <li
          key={`nonReoccuringSeshLi-startNew`}
          className="nonReoccuringSessionEntry"
        >
          <button
            onClick={() => setSelectedSession(-1)}
            className="nonReoccuringNewEntry"
          >
            +
          </button>
        </li>,
      );
    }
    setFormattedSessionList(returnSet);
  };
  const addNewSession = () => {
    const fields = getFields(null);
    if (fields[2].length === 0) {
      setMessage("Location cannot be empty");
      return;
    } else {
      addFunction(fields);
      setSelectedSession(0);
      setMessage("Session Added!");
    }
  };

  const updateSession = () => {
    const sessionID = parseInt(event.target.getAttribute("value"));
    const fields = getFields(sessionID);
    if (fields[2].length === 0) {
      setMessage("Location cannot be empty");
      return;
    } else {
      updateFunction(fields);
      setSelectedSession(0);
      setMessage("Session Updated!");
    }
  };

  const cancelOrDelete = () => {
    const sessionID = parseInt(event.target.getAttribute("value"));
    if (sessionID !== selectedSession) {
      deleteFunction(sessionID);
      setMessage("Session Deleted!");
    } else {
      setSelectedSession(0);
    }
  };
  const getFields = (id) => {
    if (id !== null) {
      return [
        document
          .getElementById(`updateSessionDatetime-${id}`)
          .value.split("T")[0],
        document
          .getElementById(`updateSessionDatetime-${id}`)
          .value.split("T")[1],
        document.getElementById(`updateSessionLocation-${id}`).value,
        document.getElementById(`updateSessionNotes-${id}`).value,
        document.getElementById(`updateSessionEndTime-${id}`).value,
        id,
      ];
    } else {
      return [
        document
          .getElementById(`updateSessionDatetime-new`)
          .value.split("T")[0],
        document
          .getElementById(`updateSessionDatetime-new`)
          .value.split("T")[1],
        document.getElementById(`updateSessionLocation-new`).value,
        document.getElementById(`updateSessionNotes-new`).value,
        document.getElementById(`updateSessionEndtime-new`).value,
      ];
    }
  };

  return (
    <div className="nonWeeklyWrapper">
      <h2 className="samDashSubsubheader">
        Create, Modify, or Delete Non-Weekly Sessions
      </h2>
      <ul className="nonReoccuringSessionList">{formattedSessionList}</ul>
      <p id="nonReoccuringMessages">{message}</p>
    </div>
  );
}

export default NonWeeklySession;
